.settings {
  width: calc(var(--card-width) * 1.2 * var(--card-ratio));
  height: calc((var(--card-height) * 1.2 * var(--card-ratio)) * 0.88);
  padding-top: calc(0.12 * (var(--card-height) * 1.2 * var(--card-ratio)));
}
.settings .title {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: grab;
  width: calc((var(--card-width) * 1.2 * var(--card-ratio)) - (3px * 2));
  height: calc(0.12 * (var(--card-height) * 1.2 * var(--card-ratio)));
  line-height: calc(0.12 * (var(--card-height) * 1.2 * var(--card-ratio)));
}
.settings .title h3 {
  margin: 0;
}
