.draggable-item {
  cursor: grab;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(var(--card-ratio) * var(--card-width));
  height: calc(var(--card-ratio) * var(--card-height));
}


.card-group.options .options {
  display: inline-block;
}

.card-group .options {
  position: relative;
  display: none;
  border-radius: 1em;
  width: calc(var(--card-ratio) * var(--card-width));
  height: calc(var(--card-ratio) * var(--card-height));
  background-color: #76B4FF;
}
.card-group .options hr {
  margin: 0;
  border-top: 1px solid #61ABFF;
  border-bottom: 1px solid #4073FF;
}
.card-group .options h3 {
  padding: 10px 0;
  margin: 0;
  color: #000;
}

.card-group .options ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.neodrag,
.neodrag-dragging {
  border-radius: 1em;
}

.neodrag-dragging {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
}

.has-overlap {
  box-shadow: 0 0 16px 0 rgba(255, 255, 255, 0.8);
}


.card-group .options ul li:hover {
  background-color: #91C7FF;
}
.card-group .options ul li {
  cursor: pointer;
  display: block;
  padding: 7px 0;
  text-decoration: none;
  color: #333333;
}

.card-group .options,
.card-group .card {
  --idx: 999;
  position: absolute;
  bottom: calc(0.75px * var(--idx));
  left: calc(0.8px * var(--idx));
}
