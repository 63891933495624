:root {
  --teal: #008080;
  --white: #e6f1ff;
}

.settings {
  background: rgba(255, 255, 255, 0.9);
  color: black;
  border-radius: 0.5em;
  width: 300px;
  height: 400px;
  overflow: auto;
  cursor: default;
}
.settings a {
  color: #000;
}
.settings a:hover {
  color: #000;
  text-decoration: underline;
}
.settings .title {
  border-radius: 0.5em 0.5em 0 0;
  background: rgba(255, 255, 255, 0.8);
  padding: 3px;
  cursor: grab;
}
.settings .content {
  text-align: left;
  padding: 5px 10px;
  position: relative;
}
.settings .content.info {
  text-align: center;
  margin: 0 20px;
  border-radius: 1em;
  background-color: rgba(254, 255, 222, 0.8);
}
.settings .flash {
  background-color: rgb(255, 231, 118);
  border: 1px solid rgb(229, 193, 78);
  padding: 10px;
  width: calc(100% - 20px);
  border-radius: 0.25em;
  margin-top: 10px;
}
.settings .content p {
  font-family: var(--font-family);
  font-weight: normal;
}
.settings .item {
  margin: 20px auto;
  background-color: #ffffff;
  padding: 15px 0 15px 15px;
  border-radius: 0.5em;
}

.settings .item .option {
  cursor: pointer;
}

.settings .item.card-size span.value,
.settings .item.volume span.value {
  margin: 0 20px;
}

.settings .item label.item-label {
  display: inline-block;
  width: 75px;
  text-align: left;
}
.settings .item.card-size span,
.settings .item.volume span {
  font-size: 20px;
}
.settings .item .option:hover {
  text-shadow: 0 1px 10px #FFD700, 0 2px 10px #FFAB00;
}
.settings .close {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
}
.settings .close:hover {
  text-shadow: 0 1px 10px #FFD700, 0 2px 10px #FFAB00;
}
