@import url('https://fonts.cdnfonts.com/css/gill-sans-mt-2');

:root {
  --teal: #008080;
  --white: #e6f1ff;
  --font-family: 'Gill Sans MT', 'Gill Sans', sans-serif;
}

#root {
  width: 100vw;
  height: 100vh;
  display: block;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: var(--font-family);
  color: var(--white);

  background-color: var(--teal);
  background-image: url('../public/assets/img/felt.png');

  height: 100vh;
  overflow: hidden;
}

a {
  color: var(--white);
  font-weight: bold;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
