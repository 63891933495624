:root {
  --card-ratio: 0.7;
  --card-width: 300px;
  --card-height: 420px;
  --card-border: 1px;

  --gold-one: #D4AF37;
  --gold-two: #C6A734;
  --gold-three: #B7952F;
}

.card {
  width: calc(var(--card-ratio) * var(--card-width));
  height: calc(var(--card-ratio) * var(--card-height));
  text-align: center;
  display: inline-block;
  position: relative;

  border-radius: 1em;
  padding: var(--card-border);
  background: linear-gradient(to top, var(--gold-three) 0%, var(--gold-two) 1%, var(--gold-two) 25%, var(--gold-one) 25%, var(--gold-one) 100%);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
}

.options {
  font-size: calc(24px * var(--card-ratio));
}

.card.definition .title,
.card.back .title {
  display: none;
}

.card .definition,
.card .definition a {
  color: #000;
}

.card.text .definition {
  display: inline-block;
  text-align: left;
}

.card .definition dl {
  margin: 0;
}

.card .definition dt {
  text-align: center;
  font-size: calc(30px * var(--card-ratio));
}
.card .definition dd {
  margin: 35px 0 25px;
}

.card .definition {
  --padding: 10px;
  position: absolute;
  top: 1px;
  left: 1px;
  padding: var(--padding);
  display: none;
  border-radius: 1em;
  width: calc(var(--card-ratio) * var(--card-width) - var(--padding) * 2);
  height: calc(var(--card-ratio) * var(--card-height) - var(--padding) * 2);
  background-color: #76B4FF;
}

.card .definition ul {
  text-align: center;
  bottom: 0;
  padding: 0;
  margin: 0;
  list-style: none;
}

.card .definition li:hover {
  background-color: #91C7FF;
}

.card .definition li {
  border-top: 2px solid #4073FF;
  cursor: pointer;
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333333;
}

.card .title {
  display: block;
  font-size: calc(30px * var(--card-ratio));
  width: 100%;
  position: absolute;
  line-height: calc(0.22 * var(--card-ratio) * var(--card-height));
  top: 0;
  left: 0;
  text-align: center;
}

.card .card-img {
  border-radius: 1em;
  width: calc(var(--card-ratio) * var(--card-width));
  height: calc(var(--card-ratio) * var(--card-height));
  background-size: 100%;
}

.card.front .card-back,
.card.back .card-front,
.card:not(:last-child) span.title,
.card:not(:last-child) img {
  display: none;
}
